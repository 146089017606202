import React from 'react';

import {graphql, useStaticQuery} from 'gatsby';
import {Helmet} from 'react-helmet';

export type SEOProps = {
  title: string;
  description: string;
  image?: string | null;
  url?: string;
  tags?: Array<string>;
  author?: string;
  publishDate?: string;
  siteUrl: string;
  isBlogPost?: boolean;
  noIndex?: boolean;
};

export const SEO: React.FC<SEOProps> = ({
  title,
  description,
  image,
  siteUrl,
  url,
  tags,
  author,
  publishDate,
  isBlogPost = false,
  noIndex = false,
}) => {
  const metaImage = image?.startsWith('http:') || image?.startsWith('https:') ? image : `${siteUrl}${image}`;
  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={metaImage} />
      <meta httpEquiv="x-ua-compatible" content="IE=edge" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      {/* OpenGraph tags */}
      <meta property="og:url" content={url || siteUrl} />
      {isBlogPost ? <meta property="og:type" content="article" /> : null}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {metaImage !== undefined && <meta property="og:image" content={metaImage} />}

      {/* Optional Editorial OpenGraph tags */}
      {!!author && <meta name="author" content={author} />}
      {!!author && <meta property="article:author" content={author} />}
      {!!publishDate && <meta property="article:publish_date" content={publishDate} />}
      {tags?.map(tag => (
        <meta key={tag} property="article:tag" content={tag} />
      ))}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={metaImage} />

      {/* SEO Schema */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Corporation",
            "name": "Chief",
            "alternateName": "Chief Women's Network",
            "url": "https://chief.com/",
            "logo": "https://store.chief.com/cdn/shop/files/Chief_Logo_Large_RGB_130x.png?v=1640121933",
            "sameAs": [
              "https://www.facebook.com/joinchiefnow",
              "https://twitter.com/JoinChiefNow",
              "https://www.youtube.com/@chiefnetwork",
              "https://www.linkedin.com/company/joinchief",
              "https://en.wikipedia.org/wiki/Chief_(women%27s_network)",
              "https://chief.com/",
              "https://golden.com/wiki/Chief-JNNKDR",
              "https://www.crunchbase.com/organization/chief-6603",
              "https://theorg.com/org/chief",
              "https://pitchbook.com/profiles/company/233719-66"
            ]
          }
        `}
      </script>
      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

export const SEOWithQuery: React.FC<Partial<SEOProps>> = props => {
  const {
    site: {siteMetadata},
  } = useStaticQuery(graphql`
    query SEOWithQueryQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
          image
        }
      }
    }
  `);

  return (
    <SEO
      title={siteMetadata.title}
      description={siteMetadata.description}
      siteUrl={siteMetadata.siteUrl}
      image={siteMetadata.image}
      {...props}
    />
  );
};
