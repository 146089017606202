import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import {segmentAnalytics} from 'src/utils/analytics';

const isBrowser = () => typeof document !== 'undefined';

interface AnalyticsEvent {
  eventName: string;
  category: string;
  [properties: string]: unknown;
}

const formatEventProperties = (event: AnalyticsEvent): {[key: string]: unknown} => {
  const properties: {[key: string]: unknown} = {};
  for (const key in event) {
    properties[snakeCase(key)] = event[key];
  }
  return properties;
};

export const trackPageView = debounce(() => {
  const ldJsonString = window?.document?.querySelector('script[type="application/ld+json"]')?.innerHTML;
  const ldJson = ldJsonString ? JSON.parse(ldJsonString) : null;
  isBrowser() &&
    segmentAnalytics(window).page(undefined, {
      article_category: ldJson?.articleSection || undefined,
      published_date: ldJson?.datePublished || undefined,
    });
}, 250);

export const trackEvent = (event: AnalyticsEvent): void => {
  // Make sure all event properties are formatted correctly.
  const properties = formatEventProperties(event);

  // Send the event and all event properties to Segment.
  isBrowser() && segmentAnalytics(window).track(event.eventName, properties);
};

type PageLocation =
  | 'header'
  | 'hero'
  | 'mobileNav'
  | 'pageBottom'
  | 'footer'
  | 'banner'
  | 'articleFooter'
  | 'gridCard'
  | 'membershipOptions';

export const Events = {
  didClickNav: (properties: {item: string; pageLocation: PageLocation}): void => {
    trackEvent({
      eventName: `Nav Clicked`,
      category: `click.${properties.pageLocation}.${camelCase(properties.item)}`,
      label: properties.item,
      nav_location: properties.pageLocation,
      nav_item_name: properties.item,
      ...properties,
    });
  },
  didSubmitForm: (properties: {page: string; form: string}): void => {
    trackEvent({
      eventName: `Form Submitted`,
      category: `submit.${camelCase(properties.page)}.${camelCase(properties.form)}`,
      ...properties,
    });
  },
  didSubmitApplication: (): void => {
    trackEvent({
      eventName: 'Application Submitted',
      category: `submit.apply.application`,
    });
  },
  didOptInToChiefUpdates: (): void => {
    trackEvent({
      eventName: 'Chief Updates Opt in Clicked',
      category: `click.apply.chiefUpdatesOptIn`,
    });
  },
  didClickJob: (properties: {jobTitle: string; jobUrl: string}): void => {
    trackEvent({
      eventName: 'Careers Job Clicked',
      category: `click.careers.job`,
      ...properties,
    });
  },
  didClickButton(properties: {item: string; pageLocation: PageLocation; sectionName: string}): void {
    trackEvent({
      eventName: `Button Clicked`,
      category: `${startCase(properties.item)}`,
      label: `${startCase(properties.sectionName)} - ${startCase(properties.pageLocation)}`,
      location: `${startCase(properties.pageLocation)}`,
      section_name: `${startCase(properties.sectionName)}`,
      ...properties,
    });
  },
  didClickImage(properties: {item: string; pageLocation: PageLocation; sectionName: string; linkUrl: string}): void {
    trackEvent({
      eventName: `Image Clicked`,
      category: `${startCase(properties.item)}`,
      label: properties.linkUrl,
      link_url: properties.linkUrl,
      section_name: `${startCase(properties.sectionName)}`,
      ...properties,
    });
  },
  didClickArticle(properties: {item: string; pageLocation: PageLocation; sectionName: string; linkUrl: string}): void {
    trackEvent({
      eventName: `Article Clicked`,
      category: `${startCase(properties.item)}`,
      label: properties.linkUrl,
      link_url: properties.linkUrl,
      section_name: `${startCase(properties.sectionName)}`,
      ...properties,
    });
  },
  didClickLink(properties: {clickText: string; clickUrl: string}): void {
    trackEvent({
      eventName: `Link Clicked`,
      category: `Link Clicked`,
      label: properties.clickUrl,
      ...properties,
    });
  },
  didClickSocial(properties: {type: string}): void {
    trackEvent({
      eventName: `Social Clicked`,
      category: properties.type,
      label: properties.type,
      social_type: properties.type,
      shared_to: properties.type,
      ...properties,
    });
  },
  didSubscribePodcast(properties: {medium: string; form: string; section: string}): void {
    trackEvent({
      eventName: `Podcast Subscribe`,
      category: 'Article Podcast',
      label: properties.medium,
      form_name: properties.form,
      podcast_medium: properties.medium,
      section_name: properties.section,
      ...properties,
    });
  },
};

export type Events = typeof Events;

// Click wrapper for Firework CTAs
export const FireworkCTAClick = (evt: MouseEvent, cta: string, clickUrl: string): void => {
  Events.didClickLink({clickText: `${cta}.cta`, clickUrl});
};

export const FireworkCTAButtonClick = (properties: {
  category: string;
  pageLocation: PageLocation;
  sectionName: string;
}): void => {
  trackEvent({
    eventName: `Button Clicked`,
    label: `${startCase(properties.sectionName)} - ${startCase(properties.pageLocation)}`,
    location: `${startCase(properties.pageLocation)}`,
    section_name: `${startCase(properties.sectionName)}`,
    ...properties,
  });
};
